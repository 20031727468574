import { Bank, CaretRight, ClockCounterClockwise, CurrencyInr, Icon, Scan } from "@phosphor-icons/react";
import { Menu } from "antd";
import toast from "components/common/Toaster";
import { TWalletRechargeModalPropsType } from "components/WalletRechargeModal";
import { useWallet } from "context";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Mixpanel, MIXPANEL_EVENTS } from "utils/mixpanel";
import { Modal } from "x-wings";
import HistoryItem from "../HistoryInMaintenanceItem";
import NeftItem from "../NeftItem";
import UpiItem from "../UpiItem";

export type WalletRechargeModalItems = "upi" | "neft" | "cards" | "history";

export const WALLET_MENU_ITEMS: Array<{
	key: WalletRechargeModalItems;
	name: string;
	icon: Icon;
	divider?: boolean;
	comingSoon?: boolean;
}> = [
	{
		key: "upi",
		name: "UPI",
		icon: Scan
	},
	{
		key: "neft",
		name: "NEFT/RTGS",
		icon: Bank
	},
	// {
	// 	key: "cards",
	// 	name: "Cards",
	// 	icon: CreditCard
	// },
	{
		key: "history",
		name: "Wallet history",
		icon: ClockCounterClockwise,
		divider: true
	}
];

export const WALLET_MENU_ITEMS_MAP = {
	upi: <UpiItem />,
	neft: <NeftItem />,
	// cards: <CardsItem />,
	history: <HistoryItem />
};

const DesktopWalletRechargeModal: React.FC<TWalletRechargeModalPropsType> = ({ open, onClose }) => {
	const { walletDetails, getFormattedWalletBalance } = useWallet();
	const [searchParams, setSearchParams] = useSearchParams();

	const activeWalletTab = searchParams.get("active-wallet-tab") as WalletRechargeModalItems | null;

	const [selectedKeys, setSelectedKeys] = useState<WalletRechargeModalItems[]>([activeWalletTab || "upi"]);

	const handleItemSelect = ({ key }: { key: WalletRechargeModalItems }) => {
		Mixpanel.track(MIXPANEL_EVENTS.WALLET_SIDEBAR_SELECT, { selectedKey: key });
		setSelectedKeys([key]);
	};

	useEffect(() => {
		const paymentStatus = searchParams.get("payment-status") as "success" | "failed" | null;

		if (paymentStatus === "failed") {
			toast.error("Payment failed. Please try again.", {
				icon: <CurrencyInr />
			});
			setSearchParams(
				(prev) => {
					prev.delete("payment-status");
					return prev;
				},
				{ replace: true }
			);
		}
	}, [searchParams, setSearchParams]);

	return (
		<Modal
			open={open}
			onCancel={onClose}
			centered
			destroyOnClose
			keyboard
			closeIcon={false}
			title={null}
			footer={null}
			zIndex={9999}
			width={800}
			className="[&_.ant-modal-body]:!bg-gray-100 [&_.ant-modal-body]:!p-0 [&_.ant-modal-content]:!h-[600px] [&_.ant-modal-content]:!p-0 [&_.ant-modal-content]:!shadow-xl [&_.ant-modal-content]:!ring-1 [&_.ant-modal-content]:!ring-gray-900/10"
			styles={{ body: { display: "grid", gridTemplateColumns: "224px auto", height: "100%" } }}>
			<aside className="relative flex w-[224px] flex-col px-4 py-8">
				<Menu mode="inline" selectedKeys={selectedKeys}>
					{WALLET_MENU_ITEMS.map((item) => {
						const Icon = item.icon;
						return (
							<React.Fragment key={item.key}>
								{item.divider && (
									<Menu.Divider className="!mx-2 !my-2 !border-b !border-t !border-b-white !border-t-gray-200" />
								)}
								<Menu.Item
									onClick={() => handleItemSelect({ key: item.key })}
									disabled={item.comingSoon}
									className="!h-8 !px-4 !py-1 !leading-none !text-primary-600 [&_.ant-menu-title-content]:ms-2"
									icon={<Icon size={16} weight="bold" />}
									key={item.key}>
									<span className="flex items-center justify-between gap-2">
										{item.name}
										{selectedKeys[0] === item.key && <CaretRight size={16} weight="bold" />}
										{item.comingSoon && (
											<span className="inline-flex items-center justify-center gap-2 rounded bg-orange-100 px-1 pb-px text-center text-xs font-semibold leading-none tracking-tight text-orange-700">
												Coming soon
											</span>
										)}
									</span>
								</Menu.Item>
							</React.Fragment>
						);
					})}
				</Menu>
				{walletDetails && (
					<div className="absolute inset-x-2 bottom-2 mt-auto rounded-lg bg-gray-50 p-2 shadow-sm ring-1 ring-gray-900/10">
						<p className="mb-0.5 text-xs font-medium text-gray-600">Wallet balance</p>
						<p className="flex items-center gap-1 text-lg font-semibold text-gray-600">
							<span>{getFormattedWalletBalance(walletDetails.balance)}</span>
						</p>
					</div>
				)}
			</aside>
			<div className="my-2 me-2 rounded-lg bg-white shadow-md ring-1 ring-gray-900/10">
				{WALLET_MENU_ITEMS_MAP[selectedKeys[0] as keyof typeof WALLET_MENU_ITEMS_MAP]}
			</div>
		</Modal>
	);
};

export default DesktopWalletRechargeModal;

