import { CopySimple, Link, WarningCircle } from "@phosphor-icons/react";
import { ReactComponent as ImpsIcon } from "assets/icons/Finance/imps-icon.svg";
import toast from "components/common/Toaster";
import { useWallet } from "context";
import { TWalletVirtualAccountReceiver } from "context/WalletContext/Api";
import { copyText } from "utils";
import { Mixpanel, MIXPANEL_EVENTS } from "utils/mixpanel";
import { clsx } from "x-wings";
import TermsAndConditionPopover from "../TermsAndConditionPopover";

export type TNeftItemLabelKey = keyof Pick<
	TWalletVirtualAccountReceiver["BANK_TRANSFER"],
	"ifsc" | "account_number" | "bank_name" | "name" | "account_type"
>;
const NEFT_DETAILS_LABEL_MAP: Record<TNeftItemLabelKey, string> = {
	ifsc: "IFSC Code",
	account_number: "Account Number",
	bank_name: "Bank Name",
	name: "Account Holder Name",
	account_type: "Account Type"
};

const NeftItem = () => {
	const { virtualAccount, isVirtualAccountLoading } = useWallet();

	const handleCopy = (label: string, text: string) => {
		Mixpanel.track(MIXPANEL_EVENTS.WALLET_COPY_BANK_DETAILS, { label });
		copyText(text);
		toast.info(label + " copied!", {
			icon: <Link />
		});
	};

	return (
		<div className="relative flex h-full flex-col items-stretch md:p-8">
			<ImpsIcon className="mx-auto mb-10 hidden md:block" />
			<p className="mb-10 hidden text-center text-sm font-medium text-gray-500 md:block">
				Send your funds on these bank details below:
			</p>
			<div className="grid grid-cols-[repeat(auto-fill,minmax(180px,1fr))] gap-x-7 gap-y-5 md:gap-x-12 md:gap-y-8">
				{/* {error && (
					<p className="text-center text-xs font-medium text-rose-600 col-span-full">
						Oops! Something went wrong. Please try again later. If the problem continues, please contact
						support.
					</p>
				)} */}
				{isVirtualAccountLoading &&
					Array.from({ length: 4 }).map((_, index) => (
						<div key={index} className="animate-pulse space-y-2">
							<div className="h-4 w-3/4 rounded bg-gray-200" />
							<div className="h-4 w-1/2 rounded bg-gray-200" />
						</div>
					))}
				{virtualAccount &&
					!isVirtualAccountLoading &&
					Object.entries(NEFT_DETAILS_LABEL_MAP).map(([key, value]) => {
						if (virtualAccount["BANK_TRANSFER"][key as TNeftItemLabelKey])
							return (
								<div key={key} className="">
									<p className="mb-0.5 text-sm font-normal text-gray-700">{value}:</p>
									<div className="flex items-center text-lg font-medium text-gray-900">
										{virtualAccount["BANK_TRANSFER"][key as TNeftItemLabelKey]}
										<CopySimple
											role="button"
											size={20}
											className="ms-auto cursor-pointer text-gray-400"
											onClick={() =>
												handleCopy(
													value,
													virtualAccount["BANK_TRANSFER"][key as TNeftItemLabelKey] ?? ""
												)
											}
										/>
									</div>
								</div>
							);
					})}
			</div>

			<Alert className="mt-4 md:mt-auto" />
			<TermsAndConditionPopover />
		</div>
	);
};

const Alert: React.FC<{ className?: string }> = ({ className }) => (
	<div className={clsx("flex items-start gap-3 rounded-lg border-x-2 border-rose-600 bg-rose-50 p-3", className)}>
		<WarningCircle size={20} weight="fill" className="shrink-0 text-rose-600" />
		<p className="flex-1 text-sm font-medium text-rose-950">
			Please use your <span className="font-semibold">Primary Banking App/Website</span> to transfer funds!
			Transaction made using GPay, PhonePe, PayTm will not reflect as Bank Transfer.
		</p>
	</div>
);

const ComeBackSoon: React.FC = () => (
	<div className=" m-6 flex items-start gap-3 rounded-lg border-x-2 border-blue-600 bg-blue-50 p-3">
		<WarningCircle size={20} weight="fill" className="shrink-0 text-blue-600" />
		<p className="flex-1 text-sm font-medium text-blue-950">
			We are committed to enhancing the security of your payments. Please note that bank transfer functionality
			will be enabled shortly. Thank you for your patience as we work to provide you with a safer transaction
			experience.
		</p>
	</div>
);

export default NeftItem;
