/* eslint-disable react/no-unescaped-entities */
import { Card } from "antd";
import React from "react";
import { ScrollToHashElement } from "x-wings";
import "./styles.scss";

const SettingsTermsCondition: React.FC = () => {
	return (
		<Card style={{ border: "none" }} className="p-4 md:p-8">
			<ScrollToHashElement behavior="smooth" inline="center" block="center" />
			<p className="text-[#0E9384]">Last updated on 7 Nov 2024</p>
			<div className="mb-6 flex  w-full flex-col">
				<h3 className="my-2 text-4xl">Terms and Conditions</h3>
				<p className="mb-2 text-base text-gray-500">
					<a href="http://www.stampmyvisa.com" className="!text-primary-600 hover:underline">
						www.stampmyvisa.com
					</a>
					 (hereinafter referred to as StampMyVisa or SMV) is a domain name operated by Intelliglobe Travel
					Tech Private Limited. This website is offered to you conditioned on your acceptance without
					modification of the terms, conditions, and notices contained herein.
				</p>
			</div>

			<div className="terms-list mx-auto w-full text-base leading-relaxed text-gray-600">
				<ol className="">
					<li>
						<strong>Acceptance of Terms</strong>
						<div>
							<ol>
								<li>
									By accessing and using the services provided by StampMyVisa, you agree to be bound
									by these Terms and Conditions, including any updates or amendments. If you do not
									agree with these terms, kindly do not give your consent and proceed further to
									access the application.
								</li>
								<li>
									The Company reserves the right, at its discretion, to change, modify, add, or remove
									portions of these Terms at any time by posting the amended Terms. Please check these
									Terms periodically for changes. Your continued use of the Service after the posting
									of changes constitutes your binding acceptance of such changes. In addition, when
									using any services, you may be subject to any posted guidelines or rules applicable
									to such Services. All such guidelines or rules are hereby incorporated by reference
									into the Terms.
								</li>
								<li>
									This document is an electronic record in terms of the Information Technology Act,
									2000 (“IT Act”) and rules made thereunder as may be applicable, and the amended
									provisions pertaining to electronic records in various statutes as amended by the IT
									Act. This electronic record is generated by a computer system and does not require
									any physical or digital signatures.
								</li>
							</ol>
						</div>
					</li>

					<li>
						<strong>User Eligibility</strong>
						<div>
							Our services are exclusively intended for travel agents (individual or companies), online
							travel companies, MICE travel companies, and any other organisations that deal with
							international travel. By using our platform, you represent and warrant that you are a duly
							registered business entity in India and authorized to engage in international visa
							processing on behalf of your client(s).
						</div>
					</li>

					<li>
						<strong>Visa Services</strong>
						<div>
							StampMyVisa facilitates the visa application process for various countries. We act as an
							intermediary, helping you submit visa applications to the respective embassies or
							consulates. However, we do not guarantee visa approval or entry into any foreign country, as
							this is solely determined by the destination country's authorities.
						</div>
					</li>

					<li>
						<strong>Ordering Process</strong>
						<div>
							<ol>
								<li>
									By placing an Order through the Service, You warrant that You are legally capable of
									entering into binding contracts.
								</li>
								<li>
									To place a visa order, you must register and provide accurate and complete
									information.
								</li>
								<li>
									Visa orders are subject to processing fees, visa fees, and any applicable service
									charges, which you agree to pay.
								</li>
								<li>
									You must ensure that the information provided in the visa application is accurate
									and complete, as incorrect or incomplete information may result in visa rejection.
								</li>
							</ol>
						</div>
					</li>

					<li>
						<strong>Your Information</strong>
						<div>
							<ol>
								<li>
									You are responsible for maintaining the security of your account credentials,
									including your username and password. You agree not to share your account
									information with others or allow unauthorized access to your account. Notify us
									immediately if you suspect any unauthorized access to your account.
								</li>
								<li>
									In order to avail the Services provided by the Company, you will be required to
									register on the Application/web portal, You may be asked to supply certain
									information relevant to Your Order including, without limitation, Your name, Your
									email, Your phone number, Your company name, Your company business registration
									certificate(s), Your credit card number, the expiration date of Your credit card,
									Your billing address, Your shipping address and Your contact details or information
									of Your client(s) including, without limitation, travel dates, travel country,
									purpose of travel, personal documents such as Passport, Income Tax Return, Bank
									Statements, Travel tickets/itinerary , hotel tickets, personal photographs, company
									letterheads, address proofs.
								</li>
								<li>
									By submitting such information, You grant us the right to provide the information to
									payment processing third parties for purposes of facilitating the completion of Your
									Application.
								</li>
								<li>
									You represent and warrant that:
									<p>
										You have the legal right to use the credit or debit card(s) or other payment
										method(s) that You are using in connection with any application; and that the
										information You supply to us is true, correct, and complete.
									</p>
								</li>
								<li>
									Certain Countries VISA Offices/Applications may require us to complete and submit
									the relevant application form(s) online/physically for submission of Your/ Your
									client(s) details in such format as may be required by them from time to time. By
									submitting the request on our App/ web portal, you grant us the authorisation to
									complete the said form(s) based on information as provided by You and/or submit the
									form online/physically to the VISA offices/ intermediary organisations appointed by
									consulates/embassies/Visa offices of different Countries. You shall continue to
									indemnify Us for the said completion/verification as done by Us on the basis of the
									data provided by You.
								</li>
							</ol>
						</div>
					</li>

					<li>
						<strong>Visa Approval and Processing Times</strong>
						<div>
							Visa processing times and approval decisions are determined by the respective embassy or
							consulate. StampMyVisa does not control these factors and cannot be held responsible for
							delays or denials in the visa application process.{" "}
						</div>
					</li>

					<li>
						<strong>Fees and Payment</strong>
						<div>
							<ol>
								<li>
									Visa processing fees, visa fees, and service charges are subject to change without
									notice. You agree to pay all applicable fees for your visa orders.
								</li>
								<li>
									The prices/fees quoted may be revised by the Company subsequent to accepting an
									order in the event of any occurrence affecting delivery caused by government action,
									variation in, increased shipping charges, and any other matter beyond the control of
									the Company. In that event, You will have the right to cancel Your Order.
								</li>
								<li>
									Payment can be made through our secure payment gateway, and you are responsible for
									ensuring your payment details are accurate and up to date.
								</li>
								<li>
									In case of non-availability of payment gateway, payment can be made through Online
									Bank transfer (IMPS) to the bank account details that will be shared with you over
									email or an invoice raised against company details provided by you.
								</li>
								<li>
									All fees are non-refundable unless expressly stated otherwise in writing by
									StampMyVisa.
								</li>
							</ol>
						</div>
					</li>

					<li>
						<strong>Cancellation and Refund Policy</strong>
						<div>
							<ol>
								<li>
									We reserve the right to refuse or cancel Your Application at any time for certain
									reasons including but not limited to:
									<ol className="ps-5">
										<li>Downtime and availability of Embassy/third party systems</li>
										<li>Or any other unavoidable or unforeseen circumstances</li>
									</ol>
								</li>
								<li>
									We reserve the right to refuse or cancel Your Application if fraud or an
									unauthorized or illegal transaction is suspected.
								</li>
								<li>
									We reserve the right to refuse or cancel Your Application if traveller details
									shared by You are deemed to be suspected, as determined by StampMyVisa.
								</li>
								<li>
									We have a strict NO REFUND policy. StampMyVisa will not refund the Visa fees or the
									StampMyVisa service fees for any reason whatsoever. If your visa is rejected by the
									embassy/consulate or the concerned authorities, StampMyVisa will not be liable to
									offer you a refund on the fees.
								</li>
								<li>
									If You/ Your client(s) decide to overstay without legitimate approvals at a
									particular destination, in such circumstances, You shall be liable to bear the
									penalty charges, disciplinary action, legal proceedings etc. by the Government or
									other such concerned authorities. StampMyVisa will not bear any responsibility.
								</li>
							</ol>
						</div>
					</li>

					<li>
						<strong>Terms & Conditions for Whatsapp Communications</strong>
						<div>
							<ol className="[&>li>strong]:!text-base">
								<li>
									<strong>Consent:</strong>
									&nbsp; By signing up on our portal, you grant us permission to use the phone number
									provided for communication via WhatsApp. This includes updates, notifications, and
									relevant information pertaining to the services offered on the portal.
								</li>
								<li>
									<strong>Communication Scope:</strong>
									&nbsp; The communication via WhatsApp will be limited to essential service-related
									information, updates, alerts, and promotional content related to the services
									offered by our portal.
								</li>
								<li>
									<strong>Frequency and Timing:</strong>
									&nbsp; We aim to limit our communications to a reasonable frequency, ensuring they
									are relevant and useful. Messages will typically be sent during regular business
									hours, unless urgent or time-sensitive.
								</li>
								<li>
									<strong>Opt-out Option:</strong>
									&nbsp; Users have the right to opt out of WhatsApp communications at any time. To
									unsubscribe, simply reply with 'STOP' to any WhatsApp message received from our
									portal. Once opted out, you will no longer receive messages unless re-subscribed.
								</li>
								<li>
									<strong>Data Privacy:</strong>
									&nbsp; We value your privacy. Your phone number and any other personal information
									collected will be used solely for communication purposes and will not be shared,
									sold, or distributed to any third party without your explicit consent, except where
									required by law.
								</li>
								<li>
									<strong>Message Content:</strong>
									&nbsp; We commit to sending accurate, non-misleading, and relevant information
									through WhatsApp communications. However, we are not liable for the content or
									consequences resulting from links or attachments shared within these messages.
								</li>
								<li>
									<strong>User Responsibility:</strong>
									&nbsp; You are responsible for ensuring the accuracy of the provided phone number
									and promptly updating us of any changes. You agree that we are not liable for
									messages sent to an incorrect or outdated phone number provided by you.
								</li>
								<li>
									<strong>Modification of Terms:</strong>
									&nbsp; We reserve the right to modify these terms and conditions for WhatsApp
									communications. Users will be notified in advance of any substantial changes, and
									continued use of our services will imply acceptance of the updated terms.
								</li>
								<li>
									<strong>Termination:</strong>
									&nbsp; We reserve the right to terminate WhatsApp communications to any user who
									violates these terms or misuses the communication platform, without prior notice.
								</li>
							</ol>
						</div>
					</li>

					<li>
						<strong>Privacy and Data Security</strong>
						<div>
							We are committed to protecting your data. Our Privacy Policy outlines how we collect, use,
							and store your information. By using our services, you consent to our data practices as
							described in our Privacy Policy.
						</div>
					</li>

					<li>
						<strong>Intellectual Property</strong>
						<div>
							<ol className="[&_li>strong]:!text-base [&_li]:before:!content-none">
								<li>
									<strong>Ownership</strong>
									<p>
										All content, information, graphics, logos, designs, and any other material
										provided on our website are the property of StampMyVisa or our licensors, and
										they are protected by intellectual property laws.
									</p>
								</li>
								<li>
									<strong>License</strong>
									<p>
										You are granted a limited, non-exclusive, non-transferable license to access and
										use our website and services for the purpose of processing visas for your
										clients. This license does not grant you any ownership rights or rights to
										modify, reproduce, distribute, or publicly display our content.
									</p>
								</li>
								<li>
									<strong>Trademarks</strong>
									<p>
										StampMyVisa trademarks, logos, and service marks are the exclusive property of
										StampMyVisa. You may not use them without our prior written consent.
									</p>
								</li>
								<li>
									<strong>User Content</strong>
									<p>
										By submitting content to our platform, such as visa applications or reviews, you
										grant StampMyVisa a non-exclusive, worldwide, royalty-free, and perpetual
										license to use, display, and distribute such content in connection with our
										services.
									</p>
								</li>
								<li>
									<strong>Copyright Complaints</strong>
									<p>
										If you believe that your intellectual property rights have been violated on our
										website, please contact us promptly with the necessary information to address
										the issue.
									</p>
								</li>
							</ol>
						</div>
					</li>

					<li>
						<strong>Termination</strong>
						<div>
							StampMyVisa reserves the right to terminate your account or access to our services at any
							time for any reason, including violation of these terms or engaging in fraudulent
							activities. You acknowledge that the Company may do so in its sole discretion.
						</div>
					</li>

					<li>
						<strong>Liability and Disclaimers</strong>
						<div>
							<ol>
								<li>
									StampMyVisa is not liable for any damages, losses, or expenses arising from the use
									of our services.
								</li>
								<li>
									We are constantly updating our Visa related information, including, without
									limitation, document requirements, processing time, stay duration, validity,
									offerings of fees on the Service. The fees available on our Service may be
									mispriced, described inaccurately, or unavailable, and we may experience delays in
									updating information regarding our fees on the Service and in our advertising on
									other websites. We make no warranties or representations regarding visa approval or
									the accuracy of the information on our website.
								</li>
							</ol>
						</div>
					</li>

					<li>
						<strong>Indemnification and Limitation of Liability</strong>
						<div>
							<ol>
								<li>
									You agree to indemnify and defend the Company its affiliates, successors, and
									assigns, and each of their respective investors, directors, officers, employees,
									agents, and suppliers from and against any losses, claims, liabilities, damages,
									and/or costs (including reasonable attorney fees and costs) arising from your access
									to or use of the Platform or the Services, violation of these Terms, or infringement
									of any intellectual property or other rights of the Company. The Company will notify
									you of such claims and accordingly you will have to cooperate and assist and
									indemnify the Company of such losses.
								</li>
								<li>
									The Company in no event shall be liable for any content posted, transmitted,
									exchanged or received by or on behalf of any user or other person on or through the
									Platform.
								</li>
							</ol>
						</div>
					</li>

					<li>
						<strong>Governing Law and Jurisdiction</strong>
						<div>
							These Terms and Conditions are governed by the laws of India, and any disputes will be
							subject to the exclusive jurisdiction of the courts in Mumbai, Maharashtra, India.{" "}
						</div>
					</li>

					<li>
						<strong>Prohibited Activities</strong>
						<div>
							<p>
								You agree not to host, display, upload, modify, publish, transmit, storage, update, or
								share any information or User Submissions which
							</p>
							<ol className="terms-list-roman">
								<li>belongs to another person/ client(s) to which you do not have any right</li>
								<li>
									is grossly harmful, harassing, blasphemous defamatory, obscene, pornographic,
									paedophilic, libellous, invasive of another's privacy, hateful, or racially,
									ethnically objectionable, disparaging, relating or encouraging money laundering or
									gambling, or promoting enmity between different groups on the grounds of religion or
									caste with the intent to incite violence otherwise unlawful in any manner whatever;
								</li>
								<li>harm minors in any way;</li>
								<li>infringes any patent, trademark, copyright or other proprietary rights;</li>
								<li>
									deceives or misleads the addressee about the origin of such messages or knowingly
									and intentionally communicates any misinformation or information which is patently
									false and untrue or misleading in nature;
								</li>
								<li>impersonate another person;</li>
								<li>
									threatens the unity, integrity, defence, security or sovereignty of India, friendly
									relations with foreign states, or public order or causes incitement to the
									commission of any cognisable offence or prevents investigation of any offence or is
									insulting any other nation;
								</li>
								<li>
									contains software viruses or any other computer code, files or programs designed to
									interrupt, destroy or limit the functionality of any computer resource;
								</li>
								<li>violates any law for the time being in force;</li>
							</ol>
							<p>
								If you are found to be in non-compliance with the laws and regulations, these terms, or
								the privacy policy of the Site, the Company shall have the right to immediately
								terminate/block your access and usage of the Site and the Company shall have the right
								to immediately remove any non-compliant Content and or comment, uploaded by you and
								shall further have the right to take recourse to such remedies as would be available to
								the Company under the applicable laws.
								<br />
								You understand and agree that the Company may, but is not obligated to, review the User
								Submissions and may delete or remove it (without notice) in its sole and absolute
								discretion, for any reason or without assigning any reason.
							</p>
						</div>
					</li>

					<li>
						<strong>Force Majeure</strong>
						<div>
							The Company will not be held responsible for any delay or failure to adhere to the
							obligations if such delay or failure arises or is in connection to a Force Majeure event.
							Force Majeure Event' means any event or circumstance or combination of events and
							circumstances which is reasonably beyond the control of the Company and which causes or
							results in default or delay in performance of any of its obligations under this agreement
							and includes an act of God, war, hostilities, civil commotion, strikes, lockouts and other
							industrial disputes or governmental regulations/notifications, pandemic or any other reason.
						</div>
					</li>
					<li id="absconding">
						<strong>Absconding Clause</strong>
						<div>
							<ol>
								<li>
									"Absconding" refers to a situation where a traveler overstays their permitted
									duration in a foreign country without proper authorization, potentially violating
									visa terms and local immigration laws.
								</li>
								<li>
									The travel agent who placed the order with StampMyVisa shall be solely responsible
									and liable for any instances of absconding by their clients.
								</li>
								<li>
									In the event of absconding, all legal consequences, fines, penalties, and associated
									costs shall be borne entirely by the travel agent who initiated the visa application
									through our platform.
								</li>
								<li>
									StampMyVisa reserves the right to take legal action against the travel agent for any
									financial losses, reputational damage, or other adverse effects resulting from their
									client's absconding.
								</li>
								<li>
									Travel agents are required to ensure their clients fully understand and comply with
									the visa terms, including permitted duration of stay. Failure to do so may result in
									severe legal and financial repercussions for the travel agent.
								</li>
								<li>
									In cases of absconding, StampMyVisa may, at its discretion, suspend or terminate the
									travel agent's account and services without prior notice.
								</li>
								<li>
									Travel agents agree to indemnify and hold StampMyVisa harmless from any claims,
									damages, losses, liabilities, or expenses arising from or related to their clients'
									absconding.
								</li>
								<li>
									StampMyVisa shall not be held liable for any consequences, direct or indirect,
									arising from a traveler's decision to abscond or overstay their visa duration.
								</li>
							</ol>
						</div>
					</li>
					<li>
						<strong>Contact Information</strong>
						<div>
							<p>
								For questions or concerns related to these Terms and Conditions, please contact us at{" "}
							</p>
							<address>
								StampMyVisa, Ruby House, B-Wing, JK Sawant Marg, Dadar West, Dadar, Mumbai, Maharashtra
								400028
							</address>
							<p>
								Email: <a href="mailto:partners@stampmyvisa.com">partners@stampmyvisa.com</a>
							</p>
						</div>
					</li>
				</ol>

				<p>
					By using our services, you acknowledge that you have read, understood, and agreed to these Terms and
					Conditions. These terms may be updated periodically, and it is your responsibility to review them
					regularly.
				</p>
			</div>
		</Card>
	);
};

export default SettingsTermsCondition;
