import { ArrowLeft, DotsThreeVertical, Info, SignOut } from "@phosphor-icons/react";
import { Dropdown } from "antd";
import toast from "components/common/Toaster";
import { useGlobalContext } from "context";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "screens/Auth/Context";
import { Mixpanel, MIXPANEL_EVENTS } from "utils/mixpanel";
import { Button, clsx } from "x-wings";

const MobileLayout: React.FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { updateUser } = useAuthContext();
	const { handleOpenContactUsModal } = useGlobalContext();

	const isHome = location.pathname === "/home";

	const handleLogout = async () => {
		try {
			Mixpanel.track(MIXPANEL_EVENTS.LOGOUT_CLICK);
			updateUser(null);
			localStorage.removeItem("jwt-token");
			Mixpanel.reset();
			navigate("/auth");
		} catch (error) {
			toast.error("Logout failed");
			console.error(error);
		}
	};

	const handleBack = () => {
		const canGoBack = location.key !== "default";
		!canGoBack ? navigate("/home") : navigate(-1);
	};

	return (
		<div className="relative grid h-[100dvh] max-h-[100dvh] grid-rows-[min-content_1fr]">
			<header className="relative z-10 flex items-center justify-center border-b border-gray-400/30 py-5 shadow-sm">
				<ArrowLeft
					size={32}
					weight="bold"
					className={clsx(
						"absolute left-[22px] rounded-md bg-gray-100 p-1 text-gray-700 transition-all",
						isHome && "left-[-100px] opacity-0"
					)}
					onClick={handleBack}
				/>
				<Link to="/home">
					<img
						width={210}
						height={32}
						src="/assets/images/logo-with-name.svg"
						alt="StampMyVisa"
						className="h-[22px]"
					/>
				</Link>
				<Dropdown
					menu={{
						items: [
							{
								key: "logout",
								icon: <SignOut size={16} weight="fill" />,
								onClick: handleLogout,
								"data-item-red": true,
								label: "Logout"
							} as any,
							{
								key: "contactUs",
								icon: <Info size={16} weight="fill" />,
								onClick: handleOpenContactUsModal,
								label: "Help & support"
							}
						]
					}}>
					<Button
						className="absolute right-[22px] !text-gray-900"
						type="text"
						shape="circle"
						icon={<DotsThreeVertical size={24} weight="bold" />}></Button>
				</Dropdown>
			</header>
			<div className="h-full">
				<Outlet />
			</div>
		</div>
	);
};

export default MobileLayout;
