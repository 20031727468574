import { CopySimple } from "@phosphor-icons/react";
import { ArrowRight, Spinner } from "@phosphor-icons/react/dist/ssr";
import { ReactComponent as AmazonPayIcon } from "assets/icons/Finance/amazonpay-icon.svg";
import { ReactComponent as AxisBankIcon } from "assets/icons/Finance/axisbank-icon.svg";
import { ReactComponent as BhimUpiIcon } from "assets/icons/Finance/bhim-upi-icon.svg";
import { ReactComponent as GpayIcon } from "assets/icons/Finance/gpay-icon.svg";
import { ReactComponent as HdfcBankIcon } from "assets/icons/Finance/hdfcbank-icon.svg";
import { ReactComponent as IciciBankIcon } from "assets/icons/Finance/icicibank-icon.svg";
import { ReactComponent as PaytmIcon } from "assets/icons/Finance/paytm-icon.svg";
import { ReactComponent as PhonePeIcon } from "assets/icons/Finance/phonepe-icon.svg";
import { ReactComponent as SbiBankIcon } from "assets/icons/Finance/sbibank-icon.svg";
import clsx from "clsx";
import toast from "components/common/Toaster";
import { useWallet } from "context";
import { Link } from "react-router-dom";
import { copyText } from "utils";
import { Mixpanel, MIXPANEL_EVENTS } from "utils/mixpanel";
import { Button } from "x-wings";
import TermsAndConditionPopover from "../TermsAndConditionPopover";

export type TUpiItemPropsType = {
	className?: string;
};

const UpiItem: React.FC<TUpiItemPropsType> = ({ className }) => {
	const { virtualAccount, isVirtualAccountLoading } = useWallet();

	return (
		<div className={clsx("relative flex flex-col items-center gap-5 md:gap-10 md:p-8", className)}>
			<BhimUpiIcon className="hidden md:block" />

			<div className="grid min-h-44 place-items-center md:min-h-60">
				{isVirtualAccountLoading && <Spinner size={24} className="animate-spin" />}
				{virtualAccount && (
					<div className="flex flex-col items-center rounded-xl bg-white px-[5.82px] pb-[11.64px] pt-[5.82px] text-center shadow ring-1 ring-gray-900/10 md:px-2 md:pb-4 md:pt-2">
						<img
							src={virtualAccount["UPI_TRANSFER"]["qr_code"]}
							alt="qr-code"
							className="mx-[17.5px] mb-[11.6px] mt-[17.5px] size-28 md:mx-6 md:mb-4 md:mt-6 md:size-40"
							onClick={() => Mixpanel.track(MIXPANEL_EVENTS.MOBILE.WALLET_UPI_QR_CLICK)}
						/>

						<span className="text-[13px] leading-6 text-gray-900">
							{virtualAccount["UPI_TRANSFER"]["upi_handle"]}

							<CopySimple
								role="button"
								weight="bold"
								size={24}
								className="ms-1.5 inline-block cursor-pointer rounded-md bg-gray-200 p-1 text-gray-700"
								onClick={() => {
									Mixpanel.track(MIXPANEL_EVENTS.WALLET_COPY_UPI_HANDLE);
									copyText(virtualAccount["UPI_TRANSFER"]["upi_handle"]);
									toast.info("UPI Handle copied!", {
										icon: <CopySimple />
									});
								}}
							/>
						</span>
					</div>
				)}
			</div>
			{virtualAccount && (
				<Link
					to={`upi://pay?pa=${virtualAccount["UPI_TRANSFER"]["upi_handle"]}&pn=&cu=INR`}
					className="md:hidden"
					onClick={() => Mixpanel.track(MIXPANEL_EVENTS.MOBILE.WALLET_OPEN_UPI_APP_CLICK)}>
					<Button type="primary" className="flex !h-7 items-center gap-1">
						Add with UPI app <ArrowRight />
					</Button>
				</Link>
			)}
			<div className="text-center">
				<span className="mb-4 inline-block text-[10.185px] font-medium leading-[14.55px] text-gray-500 md:text-sm">
					Scan and pay with any BHIM UPI app
				</span>
				<span className="gap mb-3 flex h-3.5 items-center justify-center gap-6 py-0.5 md:h-6">
					<GpayIcon className="h-3.5 md:h-6" />
					<PhonePeIcon className="h-3.5 md:h-6" />
					<PaytmIcon className="h-3.5 md:h-6" />
					<AmazonPayIcon className="h-3.5 md:h-6" />
				</span>
				<span className="gap flex h-3.5 items-center justify-center gap-6 py-0.5 md:h-6">
					<HdfcBankIcon className="h-3.5 md:h-6" />
					<IciciBankIcon className="h-3.5 md:h-6" />
					<SbiBankIcon className="h-3.5 md:h-6" />
					<AxisBankIcon className="h-3.5 md:h-6" />
				</span>
			</div>
			<TermsAndConditionPopover />
		</div>
	);
};

export default UpiItem;
