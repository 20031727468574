import { apiInstance } from "api/instance";
import axios from "axios";



export const getEmbedSuperSetDashboards = async () => {
	const dashboards = (await apiInstance.get<ApiResponse<TDashboard[]>>("/super-set-dashboard/")).data.data;
	return dashboards;
};

export const fetchToken = async (dashboardId: string, firstName: string, lastName: string, userId: string) => {
	const { data: response } = await axios.post("https://analytics.stampmyvisa.com/api/v1/security/login", {
		username: "admin",
		password: "admin",
		provider: "db"
	});

	const { data } = await axios.post(
		"https://analytics.stampmyvisa.com/api/v1/security/guest_token/",
		{
			resources: [
				{
					id: dashboardId,
					type: "dashboard"
				}
			],
			rls: [],
			user: {
				first_name: firstName,
				last_name: lastName,
				username: userId
			}
		},
		{
			headers: {
				Authorization: `Bearer ${response.access_token}`
			}
		}
	);
	return data.token;
};

