import { ArrowLeft, CircleNotch } from "@phosphor-icons/react";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthContext } from "screens/Auth/Context";
import { MIXPANEL_EVENTS, Mixpanel } from "utils/mixpanel";
import { fetchToken, getEmbedSuperSetDashboards } from "./Api";

const Reports: React.FC = () => {
	const navigate = useNavigate();
	const { user, isMmtUser } = useAuthContext();
	const containerDivRef = useRef<HTMLDivElement>(null);
	const [dashboards, setDashboards] = useState<TDashboard[]>([]);
	const [isFetching, setIsFetching] = useState<boolean>(true);
	const [selectedDashboardId, setSelectedDashboardId] = useState<string>("");
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		getEmbedSuperSetDashboards()
			.then((dashboards) => {
				setDashboards(dashboards);
			})
			.finally(() => {
				setIsFetching(false);
			});
	}, []);

	useEffect(() => {
		if (dashboards.length === 0) return;
		const searchQueryDashBoardId = searchParams.get("dashboard");
		if (searchQueryDashBoardId) {
			const dashboard = dashboards.find((d) => d.embedDashboardId === searchQueryDashBoardId);
			if (dashboard) {
				setSelectedDashboardId(dashboard.embedDashboardId);
				return;
			}
		}
		setSelectedDashboardId(dashboards[0].embedDashboardId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dashboards]);

	useEffect(() => {
		setSearchParams({ dashboard: selectedDashboardId }, { replace: true });
	}, [selectedDashboardId, setSearchParams]);

	useEffect(() => {
		if (!selectedDashboardId) return;
		if (dashboards.length === 0) return;
		if (!containerDivRef.current) return;
		embedDashboard({
			id: selectedDashboardId,
			supersetDomain: "https://analytics.stampmyvisa.com",
			mountPoint: containerDivRef.current,
			fetchGuestToken: () =>
				fetchToken(selectedDashboardId, user?.first_name || "", user?.last_name || "", user?._id || ""),
			dashboardUiConfig: {
				filters: {
					expanded: false
				}
			}
		}).catch((error) => {
			console.error("Failed to embed dashboard", error);
		});
	}, [dashboards, selectedDashboardId, user]);

	// if (!isMmtUser || !user?.roles.includes("SUPERVISOR")) return <Navigate to="/home" replace />;

	if (isFetching) return <CircleNotch size={32} className="animate-spin text-indigo-600" />;

	if (dashboards.length === 0) return <div className="grid h-full place-items-center">No dashboards found</div>;

	const handleDashboardChange = (dashboardId: string) => {
		Mixpanel.track(MIXPANEL_EVENTS.DASHBOARD_SELECTED, {
			dashboard: dashboards.find((d) => d.embedDashboardId === dashboardId)?.dashboardName
		});
		setSelectedDashboardId(dashboardId);
	};

	return (
		<div className="grid h-full grid-rows-[min-content_min-content_auto]">
			<header
				className={clsx(
					"sticky top-0 z-50 inline-flex h-14 w-full items-center justify-between border-b border-slate-200 bg-white px-6 py-3 shadow-sm"
				)}>
				<div className="flex items-center justify-start gap-1">
					<button
						onClick={() => navigate(-1)}
						className="flex cursor-pointer items-center justify-center gap-2 rounded-md bg-white px-1 py-0.5">
						<ArrowLeft className="relative h-[18px] w-[18px] text-gray-400" />
					</button>
					<div className="relative grid h-[18px] w-[18px] place-items-center">
						<div className="h-3.5 w-px rounded-sm bg-gray-400" />
					</div>
					<div className="flex items-center justify-center gap-2 rounded-md bg-white px-2 py-0.5">
						<div className="font-['Inter'] text-sm font-normal leading-tight text-gray-500">Reports</div>
					</div>
				</div>
			</header>
			<div className="flex flex-wrap items-center gap-3 px-4 py-3">
				{dashboards.length > 0 && (
					<>
						<p className="text-sm font-medium">Dashboards</p>
						<span className="rounded-px h-5 w-px bg-gray-200" />
					</>
				)}
				{dashboards.map((dashboard) => (
					<button
						key={dashboard.embedDashboardId}
						className={clsx(
							"my-px flex h-[22px] items-center rounded-full border border-gray-400 px-2.5 transition-colors",
							selectedDashboardId === dashboard.embedDashboardId
								? "border-solid border-primary-400 bg-primary-50"
								: "border-dashed"
						)}
						onClick={() => handleDashboardChange(dashboard.embedDashboardId)}>
						<span
							className={clsx(
								"font-['Inter'] text-xs font-medium leading-tight text-gray-600",
								selectedDashboardId === dashboard.embedDashboardId && "text-primary-600"
							)}>
							{dashboard.dashboardName}
						</span>
					</button>
				))}
			</div>
			{!selectedDashboardId && <p className="mt-10 text-center">Select a dashboard to view reports</p>}
			<div
				className="superset-container relative place-self-stretch [&_iframe]:h-full [&_iframe]:w-full [&_iframe]:border-none"
				id="my-superset-container"
				ref={containerDivRef}></div>
		</div>
	);
};

export default Reports;

