import MobileModal from "components/common/MobileModal";
import SettingsTermsCondition from "components/SettingsTermsCondition/SettingsTermsCondition";
import { useGlobalContext } from "context";
import { Button, Modal } from "x-wings";

export type TNewTnCAgreementModalPropsType = {
	open: boolean;
	onAgree: () => void;
};

const NewTnCAgreementModal: React.FC<TNewTnCAgreementModalPropsType> = ({ open, onAgree }) => {
	const { isMobile } = useGlobalContext();

	const MModal = isMobile ? MobileModal : Modal;

	return (
		<MModal
			open={open}
			centered={true}
			closeIcon={null}
			closable={false}
			maskClosable={false}
			zIndex={1000000}
			title="Updates to StampMyVisa's Terms and Conditions"
			footer={
				!isMobile && (
					<Button type="primary" block onClick={onAgree}>
						I agree to the terms and conditions
					</Button>
				)
			}>
			<div className="relative h-[50vh] max-h-[50vh] overflow-auto pb-12 [&>*]:!p-0">
				<SettingsTermsCondition />
			</div>
			{isMobile && (
				<Button type="primary" block onClick={onAgree}>
					I agree to the terms and conditions
				</Button>
			)}
		</MModal>
	);
};

export default NewTnCAgreementModal;

