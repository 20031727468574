import MobileModal from "components/common/MobileModal";
import useIsMobile from "hooks/useIsMobile";
import { Modal } from "x-wings";

export type TTransitionedFromRoamyModalPropsType = {
	open: boolean;
	onClose: () => void;
};

const TransitionedFromRoamyModal: React.FC<TTransitionedFromRoamyModalPropsType> = ({ open, onClose }) => {
	const isMobile = useIsMobile();

	const MModal = isMobile ? MobileModal : Modal;

	return (
		<MModal
			open={open}
			onCancel={onClose}
			maskClosable={false}
			centered={true}
			zIndex={1000000}
			title="StampMyVisa updates: Bank account details"
			footer={null}>
			<p className="whitespace-pre-line">
				<br />
				Dear Travel Partners,
				<br />
				<br />
				We are excited to share that <span className="font-semibold text-primary-600">StampMyVisa</span> has
				transitioned from Roamy Internet Services Pvt. Ltd. to{" "}
				<span className="font-semibold text-gray-900">Intelliglobe Travel Tech Pvt. Ltd.</span>
				<br />
				<br />
				As a part of this change, the{" "}
				<span className="font-semibold text-gray-900">RBL account (A/C No: 409001731386)</span> is now{" "}
				<span className="font-semibold text-rose-700">discontinued</span>.
				<br />
				<br />
				For all future payments,
				<em>please ensure you deposit funds directly into your wallet on our platform to settle invoices.</em>
				<br />
				<br />
				Thank you for your continued support!
				<br />
				<br />
			</p>
		</MModal>
	);
};

export default TransitionedFromRoamyModal;

