import MainLayout from "components/Layouts/MainLayout";
import { lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import Reports from "screens/Reports";
import PublicRouteWrapper from "./PublicRouteWrapper";

const NotFoundPage = lazy(() => import("screens/NotFoundPage"));
const OrderCreatePage = lazy(() => import("screens/Orders/Components/OrderCreate"));
const UsaEarlyAppointment = lazy(() => import("screens/UsaEarlyAppointment"));
const CompleteBusinessDocuments = lazy(() => import("screens/CompleteBusinessDocuments"));
const ProfilePage = lazy(() => import("screens/ProfilePage/ProfilePage"));
const SettingPage = lazy(() => import("screens/SettingPage/SettingPage"));
const TermsAndCondition = lazy(() => import("screens/TermsAndCondition"));
const DocumentsRequired = lazy(() => import("screens/DocumentsRequired"));
const Auth = lazy(() => import("screens/Auth"));
const LandingPage = lazy(() => import("screens/LandingPage"));
const SettingsPrivacyPolicy = lazy(() => import("components/SettingsPrivacyPolicy/SettingsPrivacyPolicy"));
const SettingsTermsCondition = lazy(() => import("components/SettingsTermsCondition/SettingsTermsCondition"));
const MobileHome = lazy(() => import("screens/Mobile/Home"));
const MobileWallet = lazy(() => import("screens/Mobile/Wallet"));
const MobileDocumentsRequired = lazy(() => import("screens/Mobile/DocumentsRequired"));
const MobileOrderList = lazy(() => import("screens/Mobile/OrderList"));
const MobileOrderDetails = lazy(() => import("screens/Mobile/OrderDetails"));
const OrderList = lazy(() => import("screens/OrderList"));
const OrderDetailsPage = lazy(() => import("screens/Orders/Components/OrderDetails"));

export type TRoute = {
	path?: RouteObject["path"];
	element?: RouteObject["element"];
	mobile?: JSX.Element | undefined | boolean;
	children?: TRoute[];
};

export const convertToMobileRoute = (route: TRoute[]) => {
	let map: TRoute[] = [];
	for (let ri = 0; ri < ROUTES.length; ri++) {
		const route = ROUTES[ri];
		if (route.mobile === null) {
			continue;
		}
		if (route.children) route.children = convertToMobileRoute(route.children);
		map.push({
			...route,
			element: route.mobile ?? route.element
		});
	}
	return map;
};
//if mobile route is a different component, pass it as mobile
//if mobile route is same as desktop route, do not pass it
//if mobile route is not present, pass false
export const ROUTES: TRoute[] = [
	{
		element: <MainLayout />,
		children: [
			{
				path: "/home/*",
				element: <OrderCreatePage />,
				mobile: <MobileHome />
			},
			{
				path: "/wallet/*",
				mobile: <MobileWallet />
			},
			{
				path: "/documents-and-pricing/*",
				element: <DocumentsRequired />,
				mobile: <MobileDocumentsRequired />
			},
			{
				path: "/profile",
				element: <ProfilePage />,
				mobile: false
			},
			{
				path: "/settings",
				element: <SettingPage />,
				mobile: false
			},

			{
				path: "/terms-and-condition",
				element: <TermsAndCondition />
			},
			{
				path: "/privacy-policies",
				element: <TermsAndCondition />
			},
			{
				path: "/order",
				children: [
					{
						path: "",
						element: <Navigate to="/order/list" replace={true} />
					},
					{
						path: "list",
						element: <OrderList />,
						mobile: <MobileOrderList />
					},
					{
						path: "details/:orderId/*",
						element: <OrderDetailsPage />,
						mobile: <MobileOrderDetails />
					}
				]
			},
			{
				path: "/complete-business-documents",
				element: <CompleteBusinessDocuments />
			},
			{
				path: "/usa-early-appointment",
				element: <UsaEarlyAppointment />
			},
			{
				path: "/reports",
				element: <Reports />
			}
		]
	},

	/* PUBLIC_ROUTES */
	{
		element: <PublicRouteWrapper />,
		children: [
			{
				element: <Auth />,
				path: "/auth/*"
			},
			{
				element: <LandingPage />,
				path: "/"
			},
			{
				element: <SettingsTermsCondition />,
				path: "/terms"
			},
			{
				element: <SettingsPrivacyPolicy />,
				path: "/privacy"
			},
			{
				path: "*",
				element: <NotFoundPage />
			}
		]
	}
];

