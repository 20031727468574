import { ReactComponent as WalletHistoryMaintenanceIcon } from "assets/icons/WalletHistoryMaintenance.svg";

const HistoryInMaintenanceItem: React.FC = () => {
	return (
		<div>
			<h4 className="border-b border-gray-200 text-center text-base leading-[48px] text-gray-700">
				Wallet history
			</h4>
			<div className="flex flex-col items-center pb-12 pt-9 text-center md:px-16">
				<span className="grid place-items-center rounded-full border border-[#FEDF89] bg-[#FFFAEB] pb-[25px] pe-[25px] ps-[35px] pt-[35px]">
					<WalletHistoryMaintenanceIcon height={140} width={140} />
				</span>
				<p className="mt-5 text-base tracking-[-0.12px] text-[#262626]">
					<span className="font-medium text-[#93370D]">StampMyVisa</span>&nbsp;is migrating to a new
					organisation under
					<br />
					<span className="font-medium text-[#93370D]">Intelliglobe Travel Tech Pvt. Ltd.</span>
				</p>
				<p className="mt-1 text-sm tracking-[-0.07px] text-[#262626]">
					Please contact your respective Key Accounts Manager <br />
					(KAM) for more information
				</p>
			</div>
		</div>
	);
};

export default HistoryInMaintenanceItem;

