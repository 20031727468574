import * as z from "zod";

export const TermAndConditionPolicySchema = z.object({
	accepted_by: z.null(),
	accepted_at: z.null()
});

export const PointOfContactSchema = z.object({
	name: z.string(),
	mobile_number: z.string(),
	email: z.string()
});
export type PointOfContact = z.infer<typeof PointOfContactSchema>;

export const DocumentProofSchema = z.object({
	type: z.string(),
	s3_path: z.string()
});

export const OrganisationIdSchema = z.object({
	state: z.null(),
	term_and_condition_policy: TermAndConditionPolicySchema,
	absconding_term_and_condition_policy: TermAndConditionPolicySchema,
	is_active: z.boolean(),
	_id: z.string(),
	email_verified: z.boolean(),
	is_deleted: z.boolean(),
	name: z.string(),
	point_of_contact: PointOfContactSchema,
	is_email_verified: z.boolean().optional(),
	is_wallet_enabled: z.boolean().optional(),
	created_at: z.coerce.date(),
	updated_at: z.coerce.date(),
	__v: z.number(),
	business_registration_certificate: z.string(),
	gst_certificate: z.string(),
	agency_logo: z.string(),
	document_proof: DocumentProofSchema,
	is_doc_proof_migrated: z.boolean(),
	approval_time: z.coerce.date(),
	approved_by: z.string(),
	reject_reason: z.null(),
	type: z.enum(["COMPANY", "INDIVIDUAL"]).nullable().optional(),
	status: z.enum(["UNSTARTED", "PENDING", "APPROVED", "REJECTED"]).nullable()
});

export const CurrentUserResponseSchema = z.object({
	_id: z.string(),
	frontend_app_version_key: z.string(),
	is_phone_verified: z.boolean(),
	is_email_verified: z.boolean(),
	visas_held: z.array(z.any()),
	last_login_at: z.coerce.date(),
	is_active: z.boolean(),
	is_deleted: z.boolean(),
	is_smv_user: z.boolean(),
	phone: z.string(),
	email: z.string().optional(),
	organisation_id: OrganisationIdSchema,
	first_name: z.string(),
	last_name: z.string(),
	created_at: z.coerce.date(),
	updated_at: z.coerce.date(),
	__v: z.number(),
	roles: z.array(z.enum(["TRAVEL_AGENT", "SUPERVISOR"]))
});

export const AppConfigSchema = z.object({
	// metadata: z.record(z.any()),
	metadata: z.object({
		isTransitionedFromRoamy: z.boolean().optional(),
		isNewTnCApproved: z.boolean().optional(),
		isOrgApproved: z.boolean().optional(),
		walletBalance: z.number().optional(),
		courier_docs_education_shown: z.boolean().optional(),
		appointment_docs_education_shown: z.boolean().optional()
	}),
	updated_at: z.string(),
	version_key: z.string()
}).nullable();

