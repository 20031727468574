import { Popover } from "antd";
import ShieldCheckIcon from "../ShieldCheckIcon";

const TermsAndConditionPopover = ({
	className = "absolute right-2 top-2 hidden h-7 cursor-pointer items-center gap-1.5 rounded-md bg-emerald-100 p-1.5 pe-2.5 text-green-800 shadow-sm ring-1 ring-gray-900/10 transition-[background-color] md:inline-flex [&.ant-popover-open]:!bg-gray-200"
}: {
	className?: string;
}) => (
	<Popover
		trigger={["click"]}
		arrow={false}
		zIndex={9999}
		placement="topRight"
		destroyTooltipOnHide
		rootClassName="[&_.ant-popover-inner]:!p-0 [&_.ant-popover-inner]:!ring-1 [&_.ant-popover-inner]:!ring-gray-900/10 [&_.ant-popover-title]:!mb-0 [&_.ant-popover-inner]:!shadow-lg"
		title={
			<div className="inline-flex cursor-pointer items-center gap-1.5 px-3 py-2.5 text-green-800">
				<ShieldCheckIcon width={16} height={16} />
				Terms & Conditions
			</div>
		}
		content={
			<div className="flex flex-col gap-3 border-t border-gray-900/10 p-3">
				<div className="border-s border-rose-600 bg-gradient-to-r from-[#F3F4F6] to-transparent px-2.5 text-[10px] font-normal leading-[15px] text-gray-700">
					SMV Wallet can be used for
					<br /> payments towards all your
					<br /> SMV orders
				</div>
				<div className="border-s border-rose-600 bg-gradient-to-r from-[#F3F4F6] to-transparent px-2.5 text-[10px] font-normal leading-[15px] text-gray-700">
					Any balance in wallet can not <br />
					be transferred back to a bank
					<br /> accounts as per RBI guidelines
				</div>
			</div>
		}>
		<span className={className}>
			<ShieldCheckIcon width={16} height={16} />
			Terms & Conditions
		</span>
	</Popover>
);

export default TermsAndConditionPopover;

