import ContactUsModal from "components/ContactUsModal";
import { createContext, useContext, useEffect, useState } from "react";
import { useBoolean, useEventListener } from "usehooks-ts";
import { MIXPANEL_EVENTS, Mixpanel } from "utils/mixpanel";
import { getCountries } from "./api";

const MOBILE_VIEW_WIDTH = 768;

type TGlobalContextValues = {
	isMobile: boolean;
	countries: TCountry[];
	isCountryListLoading: boolean;
	handleOpenContactUsModal: () => void;
};

const GlobalContext = createContext<TGlobalContextValues | undefined>(undefined);

export const GlobalWrapper = ({ children }: { children: React.ReactNode }) => {
	const [countries, setCountries] = useState<TCountry[]>([]);
	const [isCountryListLoading, setIsCountryListLoading] = useState(false);
	const [isMobile, setIsMobile] = useState(Math.min(window.innerWidth) < MOBILE_VIEW_WIDTH);
	const { value: openContactUsModal, setTrue: onOpenContactUsModal, setFalse: onCloseContactUsModal } = useBoolean();

	useEventListener("resize", () => {
		setIsMobile(Math.min(window.innerWidth) < MOBILE_VIEW_WIDTH);
	});

	useEffect(() => {
		(async () => {
			try {
				setIsCountryListLoading(true);
				const res = await getCountries({
					visaInfoAvailable: true
				});
				if (res.success) {
					setCountries(res.data);
				}
			} catch (error) {
				console.log("🚀 ~ error:", error);
			} finally {
				setIsCountryListLoading(false);
			}
		})();

		return () => {};
	}, []);

	return (
		<GlobalContext.Provider
			value={{
				isMobile,
				countries,
				isCountryListLoading,
				handleOpenContactUsModal: () => {
					Mixpanel.track(MIXPANEL_EVENTS.HELP_AND_SUPPORT_CLICK);
					onOpenContactUsModal();
				}
			}}>
			{children}
			<ContactUsModal isModalOpen={openContactUsModal} handleCancel={onCloseContactUsModal} />
		</GlobalContext.Provider>
	);
};

export const useGlobalContext = () => {
	const context = useContext(GlobalContext);

	if (context === undefined) {
		throw new Error("useGlobalContext must be within GlobalWrapper");
	}
	return context;
};

