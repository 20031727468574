import { apiInstance } from "api/instance";
import { z } from "zod";
import { WalletSchema, WalletTransactionSchema, WalletVirtualAccountReceiverElementSchema } from "../schema";

export type TWallet = z.infer<typeof WalletSchema>;
export const getWalletDetails = (orgId: string) => apiInstance.get<ApiResponse<TWallet>>(`/wallet/${orgId}`);

export type TWalletVirtualAccountReceiver = z.infer<typeof WalletVirtualAccountReceiverElementSchema>;
export const getWalletVirtualAccountReceiver = () =>
	apiInstance.get<ApiResponse<TWalletVirtualAccountReceiver>>("/wallet/virtual-account-receiver");

export const initiateCardPayment = (amount: number) =>
	apiInstance.post<ApiResponse>(`/wallet/initiate-pg-payment`, { amount });

export const processCardPayment = (payload: any) =>
	apiInstance.post<ApiResponse>(`/wallet/process-pg-payment`, payload);

export type TWalletTransaction = z.infer<typeof WalletTransactionSchema>;
export const getWalletHistory = ({
	page,
	pageSize,
	sortBy,
	type,
	searchText
}: {
	page: number;
	pageSize: number;
	sortBy: "asc" | "desc";
	type?: TWalletTransaction["type"];
	searchText?: string;
}) =>
	apiInstance.get<ApiResponse<{ data: TWalletTransaction[]; metadata: { totalCount: string } }>>(
		"/wallet/transaction-history",
		{
			params: { page, page_size: pageSize, sort_by: sortBy, type, search_text: searchText }
		}
	);

export const walletPayForOrder = (orgId: string, orderId: string) =>
	apiInstance.put(`/wallet/pay-for-order/${orgId}`, { order_id: orderId });

export const estimateWalletPayForOrder = (orgId: string, orderId: string) =>
	apiInstance.put(`/order-estimate/${orderId}/pay-from-wallet`, { visa_order_id: orderId, org_id: orgId });
