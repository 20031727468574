import { Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "screens/Auth/Context";

const PublicRouteWrapper = () => {
	const { user } = useAuthContext();

	return user ? <Navigate to="/home" replace /> : <Outlet />;
};

export default PublicRouteWrapper;

