import { Buildings, Users } from "@phosphor-icons/react";
import { Tabs } from "antd";
import { Modal } from "x-wings";
import { CONTACTS, OFFICES } from "./constant";

const ContactUsModal = ({ isModalOpen, handleCancel }: { isModalOpen: boolean; handleCancel: () => void }) => {
	return (
		<Modal
			footer={null}
			title="Contact us"
			open={isModalOpen}
			width={560}
			onCancel={handleCancel}
			destroyOnClose
			className="[&_.ant-drawer-body]:!pt-0">
			<Tabs
				defaultActiveKey="pointOfContacts"
				className="[&_.ant-tabs-tab]:!mt-1"
				items={[
					{
						label: (
							<span className="inline-flex items-center gap-1">
								<Users /> Point of contacts
							</span>
						),
						key: "pointOfContacts",
						children: (
							<div className="mt-4 grid min-h-max gap-3 gap-x-4 md:grid-cols-2">
								{CONTACTS.map((contact) => (
									<div key={contact.region}>
										<h3 className="font-semibold">Relationship Manager ({contact.region})</h3>
										<p className="light-gray">{contact.name}</p>
										<p className="light-gray">
											<a
												className="hover:text-primary-600 hover:underline"
												href={`tel:${contact.phone.replace(/\D/g, "")}`}>
												{contact.phone}
											</a>
										</p>
										<p className="light-gray">
											<a
												className="hover:text-primary-600 hover:underline"
												href={`mailto:${contact.email}`}>
												{contact.email}
											</a>
										</p>
									</div>
								))}
							</div>
						)
					},
					{
						label: (
							<span className="inline-flex items-center gap-1">
								<Buildings /> Offices
							</span>
						),
						key: "offices",
						children: (
							<div className="mt-4 grid gap-3 gap-x-4 md:grid-cols-2">
								{Object.entries(OFFICES).map(([city, address]) => (
									<div key={city}>
										<h3 className="font-semibold">{city} office</h3>
										<p className="light-gray max-w-[240px]">{address}</p>
									</div>
								))}
							</div>
						)
					}
				]}
			/>
		</Modal>
	);
};

export default ContactUsModal;
