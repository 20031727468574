import { useCallback, useEffect, useState } from "react";
import { getAppConfig as getAppConfigApi, putAppConfig as putAppConfigApi } from "screens/Auth/Api";
import { AppConfigSchema } from "../../screens/Auth/schema";

const getInitialAppConfig = (): TAppConfig => {
	const init = null;

	const appConfigLocalResult = AppConfigSchema.safeParse(
		JSON.parse(localStorage.getItem("app-config") ?? JSON.stringify(init))
	);

	if (appConfigLocalResult.success) {
		return appConfigLocalResult.data;
	}

	return init;
};

let recursiveCallCount = 0;

const useAppConfig = () => {
	const [appConfig, setAppConfig] = useState<TAppConfig>(getInitialAppConfig());

	useEffect(() => {
		localStorage.setItem("app-config", JSON.stringify(appConfig));
	}, [appConfig]);

	const getAppConfig = useCallback(
		async (version: string) => {
			if (version === appConfig?.version_key) return appConfig;

			try {
				const { data } = await getAppConfigApi();
				if (!data.data) return appConfig;
				setAppConfig({
					metadata: data.data.metadata,
					updated_at: data.data.updated_at,
					version_key: data.data.version_key
				});
				return data.data;
			} catch (error) {
				console.log("🚀 ~ getAppConfig ~ error:", error);
			}
		},
		[appConfig]
	);

	const patchAppConfig = useCallback(
		async (data: Record<string, any>) => {
			const appConfigData = {
				...appConfig?.metadata,
				...data
			};

			if (JSON.stringify(appConfigData) === JSON.stringify(appConfig?.metadata)) {
				if (recursiveCallCount > 5) {
					console.error(
						"🚀 ~ patchAppConfig ~ recursiveCallCount:",
						recursiveCallCount,
						"Calling patchAppConfig in a loop"
					);
					return;
				}
				recursiveCallCount++;
				console.warn("🚀 ~ patchAppConfig ~ recursiveCallCount:", recursiveCallCount);
			} else {
				recursiveCallCount = 0;
			}

			try {
				const { data } = await putAppConfigApi(appConfigData);
				if (!data.data) return appConfig;
				setAppConfig({
					metadata: data.data.metadata,
					updated_at: data.data.updated_at,
					version_key: data.data.version_key
				});
				return data.data;
			} catch (error) {
				console.log("🚀 ~ patchAppConfig ~ error:", error);
			}
		},
		[appConfig]
	);

	return { appConfig, getAppConfig, patchAppConfig };
};

export default useAppConfig;

