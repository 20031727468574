import { ArrowUpRight } from "@phosphor-icons/react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { Flag, When } from "x-wings";

export type TUsaEarlyAppointmentItemPropsType = {
	className?: string;
	isMinimized: boolean;
};

const UsaEarlyAppointmentItem: React.FC<TUsaEarlyAppointmentItemPropsType> = ({ className, isMinimized }) => {
	return (
		<Link to="/usa-early-appointment">
			<div
				className={clsx(
					"relative overflow-hidden rounded-lg bg-white p-3 shadow-sm ring-1 ring-gray-900/10",
					isMinimized && "flex aspect-square items-center justify-center !p-1",
					className
				)}>
				<div className="absolute right-[-25px] top-[-75px] h-[100px] w-[50px] rotate-[-75deg] bg-blue-500 blur-3xl"></div>
				<div className="absolute right-[-75px] top-[-25px] h-[100px] w-[50px] rotate-[-75deg] bg-rose-500 blur-3xl"></div>
				<When isTrue={!isMinimized}>
					<div className="mb-1.5 flex items-center text-base font-medium text-gray-800">
						<Flag code="USA" size="m" hasBorderRadius hasBorder className="mr-3" /> USA
						<ArrowUpRight size={20} className="ms-auto text-primary-600" />
					</div>
				</When>
				<When isTrue={!isMinimized}>
					<p className="mb-2.5 text-sm text-gray-600">
						Early Appointment Booking starting at just &nbsp;
						<br />
						<span className="font-medium text-gray-700">₹16,999</span>&nbsp;
						<span className="text-[9px] leading-5 text-gray-500">+GST / Traveller</span>
					</p>
					<span className="inline-flex items-center text-sm font-medium text-primary-600 underline">
						Book now
					</span>
				</When>
				<When isTrue={isMinimized}>
					<Flag code="USA" size="m" hasBorderRadius hasBorder gradient="real-linear" className="" />
				</When>
			</div>
		</Link>
	);
};

export default UsaEarlyAppointmentItem;
