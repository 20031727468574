import clevertap from "clevertap-web-sdk";
import { isProduction } from "env";

class CleverTap {
	constructor() {
		clevertap.privacy.push({ optOut: false }); // Set the flag to true, if the user of the device opts out of sharing their data
		clevertap.privacy.push({ useIP: false }); // Set the flag to true, if the user agrees to share their IP data
		clevertap.spa = true;
		if (!isProduction) {
			clevertap.setLogLevel(3);
		}
		clevertap.init("48Z-KK7-6R7Z"); // Replace with values applicable to you. Refer below
	}

	pushEvent(eventName: string, properties: any = undefined) {
		clevertap.event.push(eventName, properties);
	}

	onSignUp = (user: AuthUser) => {
		clevertap.profile.push({
			Site: {
				Name: user.first_name + " " + user.last_name,
				Identity: user._id, // Identity of the user
				Email: user.email, // Email address of the user
				Phone: "+91" + user.phone, // Phone (with the country code)

				// optional fields. controls whether the user will be sent email, push etc.
				"MSG-email": true, // Disable email notifications
				"MSG-push": true, // Enable push notifications
				"MSG-sms": true, // Enable sms notifications
				"MSG-whatsapp": true // Enable whatsapp notifications
			}
		});
	};

	onUserLogin = (user: AuthUser) => {
		clevertap.onUserLogin.push({
			Site: {
				Name: user.first_name + " " + user.last_name,
				Identity: user._id, // Identity of the user
				Email: user.email, // Email address of the user
				Phone: "+91" + user.phone, // Phone (with the country code)
				State: user.organisation_id.state,
				POC_Name: user.organisation_id.point_of_contact.name,
				POC_Email: user.organisation_id.point_of_contact.email,
				POC_Mobile_Number: user.organisation_id.point_of_contact.mobile_number,
				Status: user.organisation_id.status,
				Approval_Time: user.organisation_id.approval_time,
				Created_At: user.organisation_id.created_at,

				// optional fields. controls whether the user will be sent email, push etc.
				"MSG-email": true, // Disable email notifications
				"MSG-push": true, // Enable push notifications
				"MSG-sms": true, // Enable sms notifications
				"MSG-whatsapp": true // Enable whatsapp notifications
			}
		});
	};
}

export default new CleverTap();

