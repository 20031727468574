import WalletRechargeCompletedModal from "components/WalletRechargeCompletedModal";
import WalletRechargeModal from "components/WalletRechargeModal";
import toast from "components/common/Toaster";
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuthContext } from "screens/Auth/Context";
import { useBoolean, useEventListener } from "usehooks-ts";
import { isNumber } from "utils";
import { Mixpanel, MIXPANEL_EVENTS } from "utils/mixpanel";
import {
	estimateWalletPayForOrder,
	getWalletDetails,
	getWalletVirtualAccountReceiver,
	TWallet,
	TWalletVirtualAccountReceiver,
	walletPayForOrder
} from "./Api";

export type TWalletContextValues = {
	walletDetails: TWallet | null;
	isWalletLoading: boolean;
	virtualAccount: TWalletVirtualAccountReceiver | null;
	isVirtualAccountLoading: boolean;
	getFormattedWalletBalance: (balance: number, currency?: string) => string;
	rechargeWallet: () => void;
	payForOrderByOrderId: (orderId: string) => Promise<any>;
	payForOrderEstimateByOrderId: (orderId: string) => Promise<any>;
	fetchWalletDetails: () => Promise<void>;
};

const WalletContext = createContext<TWalletContextValues | undefined>(undefined);

const WalletProviderComp: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const { user, appConfig, patchAppConfig } = useAuthContext();

	const [searchParams, setSearchParams] = useSearchParams();
	const showRechargeModal = searchParams.get("recharge") === "true";

	const documentRef = useRef<Document>(document);
	const [walletDetails, setWalletDetails] = useState<TWallet | null>(null);
	const [rechargeAmount, setRechargeAmount] = useState<number>(0);
	const [isWalletLoading, setIsWalletLoading] = useState(false);
	const [virtualAccount, setVirtualAccount] = useState<TWalletVirtualAccountReceiver | null>(null);
	// const [virtualAccount, setVirtualAccount] = useState<TWalletVirtualAccountReceiver | null>({
	// 	BANK_TRANSFER: {
	// 		id: "fasdfas",
	// 		entity: "farewvas",
	// 		ifsc: "ifsc",
	// 		bank_name: "bank_name",
	// 		name: "name",
	// 		notes: [],
	// 		account_number: "acc_number",
	// 		account_type: "account_type"
	// 	},
	// 	UPI_TRANSFER: {
	// 		upi_handle: "handle@upi",
	// 		qr_code:
	// 			"https://images.unsplash.com/photo-1724963125490-44c540b1c5cc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHw5fHx8ZW58MHx8fHx8",
	// 		id: ""
	// 	}
	// }); //dummy data
	const [isVirtualAccountLoading, setIsVirtualAccountLoading] = useState(false);
	const {
		value: openRechargeModal,
		setFalse: _onCloseRechargeModal,
		setTrue: onOpenRechargeModal
	} = useBoolean(showRechargeModal);
	const {
		value: openRechargeCompletedModal,
		setFalse: onCloseRechargeCompletedModal,
		setTrue: onOpenRechargeCompletedModal
	} = useBoolean(false);

	const onCloseRechargeModal = useCallback(() => {
		_onCloseRechargeModal();
		setSearchParams(
			(prev) => {
				prev.delete("recharge");
				prev.delete("active-wallet-tab");
				return prev;
			},
			{ replace: true }
		);
	}, [_onCloseRechargeModal, setSearchParams]);

	const fetchVirtualAccountReceiver = async () => {
		let result: { status: "success" | "error"; data?: any; error?: any } = { status: "success" };
		try {
			setIsVirtualAccountLoading(true);
			const response = await getWalletVirtualAccountReceiver();
			setVirtualAccount(response.data.data);
			result.status = "success";
			result.data = response.data.data;
		} catch (error) {
			console.log("🚀 ~ fetchVirtualAccountReceiver ~ error", error);
			result.status = "error";
			result.error = error;
			result.status = "error";
			result.error = error;
		} finally {
			setIsVirtualAccountLoading(false);
		}
		return result;
	};

	useEffect(() => {
		const fetch = async () => {
			const { status } = await fetchVirtualAccountReceiver();
			if (status !== "success") {
				toast.error("Failed to fetch virtual account details. Please contact support team.");
				onCloseRechargeModal();
			}
		};
		openRechargeModal && fetch();
	}, [onCloseRechargeModal, openRechargeModal]);

	const rechargeWallet = () => {
		if (!user?.organisation_id._id || !user.organisation_id.is_wallet_enabled) return;
		Mixpanel.track(MIXPANEL_EVENTS.WALLET_RECHARGE_OPEN);
		onOpenRechargeModal();
	};

	const handleRechargeCompleted = useCallback(() => {
		onCloseRechargeModal();
		onOpenRechargeCompletedModal();
	}, [onCloseRechargeModal, onOpenRechargeCompletedModal]);

	const payForOrderByOrderId = (orderId: string) => {
		if (!orderId) return Promise.reject();
		if (!user?.organisation_id._id || !user.organisation_id.is_wallet_enabled) return Promise.reject();
		return walletPayForOrder(user.organisation_id._id, orderId);
	};

	const payForOrderEstimateByOrderId = (orderId: string) => {
		if (!orderId) return Promise.reject();
		if (!user?.organisation_id._id || !user.organisation_id.is_wallet_enabled) return Promise.reject();
		return estimateWalletPayForOrder(user.organisation_id._id, orderId)
	}

	const fetchWalletDetails = useCallback(async () => {
		if (!user?.organisation_id._id || !user.organisation_id.is_wallet_enabled) return;
		setIsWalletLoading(true);
		try {
			const { data } = await getWalletDetails(user?.organisation_id._id);
			setWalletDetails(data.data);
		} catch (error) {
			console.log("🚀 ~ getWalletDetails ~ err:", error);
		} finally {
			setIsWalletLoading(false);
		}
	}, [user?.organisation_id]);

	useEffect(() => {
		if (!walletDetails || !appConfig) return;
		const currentBalance = walletDetails.balance;
		const lastRecordedBalance = isNumber(appConfig.metadata.walletBalance)
			? (appConfig.metadata.walletBalance as number)
			: currentBalance;
		const difference = currentBalance - lastRecordedBalance;
		if (difference > 0) {
			setRechargeAmount(difference);
			handleRechargeCompleted();
		}
		if (difference !== 0 || !appConfig.metadata.walletBalance) patchAppConfig({ walletBalance: currentBalance });
	}, [appConfig, handleRechargeCompleted, patchAppConfig, walletDetails]);

	useEffect(() => {
		fetchWalletDetails();
	}, [fetchWalletDetails]);

	const timer = useRef<NodeJS.Timer | null>(null);
	useEffect(() => {
		const interval = openRechargeModal && document.visibilityState === "visible" ? 4000 : null;

		if (interval) {
			timer.current = setInterval(() => {
				fetchWalletDetails();
			}, interval);
		} else {
			timer.current && clearInterval(timer.current);
		}

		return () => {
			timer.current && clearInterval(timer.current);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openRechargeModal, document.visibilityState, fetchWalletDetails]);

	useEventListener("visibilitychange", fetchWalletDetails, documentRef);

	return (
		<WalletContext.Provider
			value={{
				walletDetails,
				isWalletLoading,
				virtualAccount,
				isVirtualAccountLoading,
				getFormattedWalletBalance(balance, currency = "₹") {
					return `${currency} ${Intl.NumberFormat("en-IN").format(balance)}`;
				},
				rechargeWallet,
				payForOrderByOrderId,
				payForOrderEstimateByOrderId,
				fetchWalletDetails
			}}>
			{children}
			<WalletRechargeModal
				open={openRechargeModal}
				onClose={onCloseRechargeModal}
				onRecharge={handleRechargeCompleted}
			/>
			<WalletRechargeCompletedModal
				open={openRechargeCompletedModal}
				onClose={onCloseRechargeCompletedModal}
				amount={rechargeAmount}
				totalAmount={walletDetails?.balance || 0}
			/>
		</WalletContext.Provider>
	);
};

export const WalletProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const { user } = useAuthContext();
	return <WalletProviderComp key={user?._id}>{children}</WalletProviderComp>;
};

export const useWallet = () => {
	const context = useContext(WalletContext);
	if (!context) {
		throw new Error("useWallet must be used within a WalletProvider");
	}
	return context;
};

